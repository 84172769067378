<template>
  <div class="row">
    <div class="col-12">
      <div v-if="user.tipo == 'dev'">
        <div class="col-sm-12">
          <div class="card-hover-shadow-2x mb-3 card">
            <div class="card-header-tab card-header">
              <div
                class="
                  card-header-title
                  font-size-lg
                  text-capitalize
                  font-weight-normal
                "
              >
                <i class="header-icon lnr-lighter icon-gradient bg-amy-crisp">
                </i>
                Actividades
              </div>
            </div>
            <div class="scroll-area-lg">
              <div class="scrollbar-container">
                <div class="p-4">
                  <div
                    class="
                      vertical-time-simple
                      vertical-without-time
                      vertical-timeline
                      vertical-timeline--animate
                      vertical-timeline--one-column
                    "
                  >
                    <div
                      v-for="actividad in actividades"
                      :key="actividad.id"
                      class="vertical-timeline-item vertical-timeline-element"
                      :class="claseActividad(actividad.tipo)"
                    >
                      <div>
                        <span
                          class="vertical-timeline-element-icon bounce-in"
                        ></span>
                        <div
                          class="vertical-timeline-element-content bounce-in"
                          style="background-color: #f4f4f4"
                        >
                          <div class="widget-content py-1">
                            <div class="widget-content-wrapper">
                              <div class="widget-content-left flex2">
                                <div class="widget-subheading opacity-7">
                                  {{ actividad.tipo }}
                                </div>
                                <div class="widget-heading">
                                  <template v-if="actividad.user">
                                    {{ actividad.user.apellido }},
                                    {{ actividad.user.nombre }} - 
                                  </template>
                                    {{ actividad.ip }}
                                </div>
                                <div class="widget-subheading opacity-7">
                                  {{actividad.created_at | formatDate}}
                                </div>
                                <div class="widget-subheading opacity-7">
                                  {{dataActividad(actividad)}}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="ps__rail-x" style="left: 0px; bottom: 0px">
                  <div
                    class="ps__thumb-x"
                    tabindex="0"
                    style="left: 0px; width: 0px"
                  ></div>
                </div>
                <div
                  class="ps__rail-y"
                  style="top: 0px; height: 400px; right: 0px"
                >
                  <div
                    class="ps__thumb-y"
                    tabindex="0"
                    style="top: 0px; height: 195px"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Axios from "axios";
import Vuex from "vuex";
export default {
  name: "Inicio",
  components: {},
  computed: {
    ...Vuex.mapState(["user"]),
  },
  data() {
    return {
      actividades: [],
    };
  },
  props: {
    titleCard: String,
    iconoTitulo: String,
  },
  mounted() {
    this.getActividades();
  },
  methods: {
    getActividades() {
      Axios.get("actividad").then((res) => {
        console.log(res.data);
        if (res.data.success) {
          this.actividades = res.data.actividades;
        }
      });
    },
    claseActividad(tipo) {
      switch (tipo) {
        case "Register":
        case "Login":
        case "Cambia Contraseña":
          return "dot-success";
        case "Logout":
          return "dot-dark";
        case "Carga Liquidacion":
          return "dot-alternate";
        case "Previsualiza Recibo":
          return "dot-focus";
        case "Imprimir Recibo":
          return "dot-info";
        case "Emitir Recibo":
          return "dot-primary";
        case "Error":
          return "dot-danger";
      }
    },
    dataActividad(actividad){
      let data = null;
      if(actividad.data){
        data = JSON.parse(actividad.data)
      }
      switch (actividad.tipo) {
        case "Register":
        case "Login":
        case "Cambia Contraseña":
        case "Logout":
          return "";
        case "Carga Liquidacion":
          return 'Liquidacion id: '+data.liquidacion_id;
        case "Previsualiza Recibo":
        case "Imprimir Recibo":
        case "Emitir Recibo":
          return 'Recibo id: '+data.recibo_id;
        case "Error":
          return 'Contexto: '+data.context;
      }
    }
  },
};
</script>